//user-side
$primary: #5f58ff;
$secondary: #6c757d;
$color-accent: #ffa500;
$light-purple: #bd7bee;
$bg-light-purple: #edecfb;
$dark-green: green;
$dark: #555555;
$light: #dee2e6;
$danger: #FF0000;


$body-bg: #fefefe;
$body-color: #555555;

$table-color: $body-color;
