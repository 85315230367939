// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./colors.scss";
@import "./typography.scss";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "~bootstrap/scss/maps";

// 5. Include remainder of required parts
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/transitions";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 8. Add additional custom code here

.text-purple-light {
  color: $light-purple;
}

.text-light-blue {
  color: #6666cc;
}

.text-yellow {
  color: $yellow;
}


/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: $primary !important;
  border-radius: 50px;
}

// Source mixin
@mixin make-container() {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin bg-light-purple() {
  background-color: $bg-light-purple;
}

@mixin text-light-color() {
  color: #772396;
}

@mixin bg-green() {
  background: $green;
}

@mixin text-green() {
  color: $green;
}

@mixin text-orange() {
  color: $orange;
}

@mixin color-btn() {
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;
  padding-left: 50px;
  border: none;
  background: linear-gradient(90deg,
      rgb(110, 98, 194) 0%,
      18.6039%,
      rgb(218, 50, 70) 46.2585%,
      70.3596%,
      rgb(204, 24, 249) 100%);
  border-radius: 8px 0px;

  &:hover {
    transform: scale(1.1);
    background: rgb(110, 98, 194);
  }
}

@mixin text-color() {
  color: #772396;
}

@mixin item-primary() {
  border: 2px solid $light-purple;
}

// Usage

.text-light-color {
  @include text-light-color();
}

.bg-light-purple {
  @include bg-light-purple();
}

.item-primary {
  @include item-primary();
}

.custom-container {
  @include make-container();
}

.color-btn {
  @include color-btn();
}

.text-color {
  @include text-color();
}

.bg-green {
  @include bg-green();
}

.text-green {
  @include text-green();
}

.text-orange {
  @include text-orange();
}

.custom-container {
  padding: 5px;

  @media (max-width: 767.98px) {
    margin: 0;
    padding: 0;
  }
}

//otp input
.otp-item {
  border: 1px solid $purple;
  width: 60px;
  border-radius: 10px;
}

//green btn
.green-btn {
  background: $dark-green;
  box-shadow: 0 3px 10px grey;
  text-align: center;
  // overflow: hidden;
  outline: none;
  width: 100%;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.infoPage {
  width: 250px;
  height: 100px;
  background: transparent url("../../public/img/Component\ 112\ –\ 28.svg") 0% 0% no-repeat padding-box;
  opacity: 1;
  background-position-x: center;
}

.load-btn {
  font-weight: bold;
  color: #772396;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;
  padding-left: 50px;
  border: 2px solid #772396;
  border-radius: 8px 0px;

  &:hover {
    transform: scale(1.1);
    background: orange;
    border: none;
  }
}

.grid-full-width {
  grid-column: 1/-1;
}

.bg-purple-dark {
  background-color: #772396;
}

.text-color-dark-Purple {
  color: #470061;
}

.text-color-Purple {
  color: #772396;
}

.b-orange {
  border: 1px solid orange;
}

.b-Purple {
  border: 1px solid #772396;
}

.custom-link {
  cursor: pointer;

  &:hover {
    color: $primary !important;
  }
}

// from home.css

.grid-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /*  grid-template-rows: 1fr; */
}

.Nav-bar {
  grid-column: 1/-1;
}

.main-nav {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  padding: 10px;
  align-items: center;
  position: relative;
  margin: 0;
}

/* li {
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}

li:hover {
  color: orange;
} */

.push {
  margin-right: auto;
}

.box {
  position: absolute;
  top: 55px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 1;
}

.box ul {
  width: 100%;
}

.box li {
  list-style: none;
  text-align: center;
  padding: 5px;
}

.box li:hover {
  background: #ddd;
}

.box hr {
  padding: 0;
  margin: 0;
}

.CardContainer {
  grid-column: 1/-1;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, auto));
}

.graphContainer {
  border-radius: 8px;
  border: 3px solid #772396;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
}

.heading {
  font-size: 20px;
}

.middletext {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
}

.bottomtext {
  margin-top: 10px;
}

.investmentContainer {
  grid-column: 1/-1;
  width: 100%;
  border-radius: 20px;
}

.SearchBar {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.btn-drop {
  display: flex;
  background: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #470061;
}

table {
  width: 100%;
  margin-top: 20px;
}

th {
  padding: 10px;
}

.top-row {
  background: #ccccff;
  color: #470061;
  font-weight: bold;
  border: none;
}

td {
  padding: 10px;
}

.footer {
  grid-column: 1/-1;
  background: linear-gradient(102deg, #e95c2b 0%, #98427b 45%, #4472af 100%) 0% 0% no-repeat;
  padding: 2rem;
  border-radius: 20% 20% 0 0;
  margin-top: 50px;
}

.alert-bar {
  grid-column: 1/-1;
  border-radius: 8px;
  background-color: #ff0000;
  padding: 10px;
}

.alert-bar-text {
  cursor: pointer;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}

.alert-bar-text:hover {
  color: #FFA011;
}

.art-board {
  min-height: 100vh;
  background-color: #ECECEC;
}

.card-flat {
  min-height: 90vh;
  background-color: #fff;
  border-radius: 20px;
}

.card-image {
  width: 100%;
  height: 300px;
  border-radius: 7px;
  background-color: #fff;
  object-fit: cover;
}

.card-title-lg {
  color: #772396;
  font-size: 38px;
  font-weight: 700;
}

.card-title-md {
  color: #772396;
  font-size: 32px;
  font-weight: 700;
}

.card-title-sm {
  color: #470061;
  font-size: 18px;
  font-weight: 700;
}

.card-text {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.card-text-sm {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.title-lg {
  grid-column: 1/-1;
  color: #470061;
  font-size: 40px;
  font-weight: 600;
}

.title-sm {
  grid-column: 1/-1;
  color: #FFA011;
  font-size: 22px;
  font-weight: 600;
}

.card-raised {
  grid-column: 1/-1;
  border-radius: 8px;
  background: transparent linear-gradient(112deg, #EDECF6 0%, #ECECFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px #00000029;
}

.label-rounded-filled {
  cursor: pointer;
  background: #470061;
  border: 2px solid #470061;
  border-radius: 18px;
  font-size: 14px;
  color: #fff;
  padding: 6px 24px;
  font-weight: 700;
}

.label-rounded-outline {
  cursor: pointer;
  border-radius: 18px;
  font-size: 14px;
  padding: 6px 22px;
  font-weight: 700;
}

.opportunity-card {
  border-radius: 16px;
  background-color: #ECECFF;
  padding: 20px 30px;
  box-shadow: 6px 3px 6px #00000029;
}

.opportunity-card-img {
  width: 50px;
  height: 50px;
  background-color: #fff;
  object-fit: contain;
}

.opportunity-card-text {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  min-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.opportunity-score-card {
  border: 1px solid;
  border-radius: 6px;
  background-color: #FFF;
  padding: 10px;
}

.opportunity-score-title {
  font-size: 22px;
  font-weight: 700;
}

.opportunity-score-sub-title {
  font-size: 16px;
  font-weight: 600;
}

.ops-theme-orange {
  border-color: #FFA011;
  color: #FFA011;
}

.ops-theme-purple {
  border-color: #772396;
  color: #772396;
}

.opportunity-score-label {
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #772396;
  background-color: #CCCCFF;
  padding: 2px 8px;
}

.bullet-text {
  display: list-item;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.bullet-text::marker {
  color: #772396;
}

.tab-action {
  border: none;
  outline: none;
  width: 100%;
  padding: 12px 24px;
  border-radius: 50px;
  font-weight: 700;
  background-color: #fff;
  color: #ffa500;
  box-shadow: 6px 3px 6px #00000029;
}

.tab-action:hover,
.tab-action-active {
  color: #fff;
  background-color: #ffa500;
}

.investors-profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  object-fit: cover;
}

.investors-profile-name {
  color: #470061;
  font-size: 16px;
  font-weight: 700;
}

.investors-profile-role {
  color: #470061;
  font-size: 14px;
}

.custom-progress-bar {
  position: relative;
  height: 12px;
  border: 2px solid #772396;
  border-radius: 10px;
}

.custom-progress-bar::-webkit-progress-bar {
  background-color: #FFF;
  border-radius: 10px;
}

.custom-progress-bar::-webkit-progress-value {
  background-color: #ffa500;
  border-radius: 10px;
}

.custom-progress-bar::before {
  content: '0';
  position: absolute;
  top: -30px;
  left: 0;
  font-size: 16px;
  color: #470061;
  font-weight: 700;
}

.custom-progress-bar::after {
  content: attr(value);
  position: absolute;
  top: -30px;
  right: 0;
  font-size: 16px;
  color: #ffa500;
  font-weight: 700;
}

.profile-stepper-container {
  display: flex;
  flex-direction: row;
}

.profile-stepper-index {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  height: 60px;
  min-width: 60px;
}

.ps-strip {
  width: 3px;
  height: 100%;
}

.profile-stepper-content {
  flex: 1;
}

.profile-stepper-title {
  color: #470061;
  font-size: 18px;
  font-weight: 700;
}

.profile-stepper-caption {
  font-size: 16px;
  font-weight: 600;
}

.profile-stepper-action {
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #ffa500;
  padding: 5px 18px;
}


//new theme

.h-100vh {
  height: 100vh;
}

.title {
  position: relative;

  &::before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 10px;
    background: $primary;
  }
}

.otp-wrapper {
  width: 300px;
}

.pointer {
  cursor: pointer;
}

.thumbnail-img,
.thumbnail-img-lg {
  display: block;
  width: 50px;
  height: 50px;
  object-fit: contain;
  // border-radius: 10px;
}

.indicator {
  height: 15px;
  width: 15px;
}

.row-hover {
  transition: .5s;

  &:hover {
    background: rgba($primary, .1);
  }

  p,
  div {
    background: transparent !important;
  }
}

.preview-loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    display: block;
    animation: zoom-in-zoom-out 5s ease-out infinite;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1, 1);
  }
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-radius: none !important;
  top: 16px !important;
  left: 6px !important;
}

.react-tel-input .form-control {
  border: none !important;
  background: transparent !important;

  width: 100% !important;
  height: 100% !important;
  
  padding: 0 0 0 48px !important; // fix mobile no align - K

  &:focus {
    box-shadow: none !important;
  }
}

.react-tel-input .flag-dropdown.open {
  z-index: 3 !important;
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.inv-status {

  padding: 2px 10px;
  border-radius: 50px;

  &.live {
    background: rgba($success, 0.1);
    color: $success;
  }

  &.closing {
    background: rgba($primary, 0.1);
    color: $primary;
  }
}

.transaction-status {
  padding: 2px 10px;
  border-radius: 50px;

  &.success {
    background: rgba($success, 0.1);
    color: $success;
  }

  &.pending {
    background: rgba($primary, 0.1);
    color: $primary;
  }

  &.failed {
    background: rgba($danger, 0.1);
    color: $danger;
  }

  &.initiated {
    background: $light;
    color: $black;
  }
}

.demat-status {

  padding: 2px 10px;
  border-radius: 50px;

  &.dematerialized {
    background: rgba($success, 0.1);
    color: $success;
  }

  &.isescrow {
    background: rgba($primary, 0.1);
    color: $primary;
  }
}

.img-contain {
  object-fit: contain;
}

.company-pdp-banner {
  width: 600px;
  height: 100%;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.co-inv-img {
  display: block;
  object-fit: contain;
  height: 120px;
  width: 120px;
}

.c-pdp-summary-tab-border {
  border: 1px solid $light !important;
  border-bottom-color: white !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $primary !important;
}

.c-pdp-summary-tab {
  text-wrap: nowrap;
  margin-bottom: -1px !important;
  border-width: 1px;
  border-color: white;
  border-bottom-color: $light;
  border-style: solid;
}

.sprint-thesis-wrapper {
  .carousel-indicators {
    button {
      margin-top: 60px !important;
      background-color: $primary !important;
      height: 20px;
      width: 20px;
      border: none !important;
      border-radius: 50%;
    }
  }
}

.summary-doc {
  width: 150px;
  height: 150px;

  .icon {
    font-size: 4rem;
  }

  p {
    width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
}

.opp-desc-wrapper {
  max-height: 500px;
  overflow-y: scroll;
}

.summary-tab-wrapper {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.drawdown-notice {
  height: 60vh;
}

.co-investor-wrapper {
  width: 190px;
}

.animate-scroll-down {
  animation: moveUpDown 2s ease-in-out infinite;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
}

.custom-link {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.custom-link:hover {
  transform: translateY(-3px);
}
